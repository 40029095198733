import ScreenSizeDetector from 'screen-size-detector';
import Splide from '@splidejs/splide';

const mainSliderOptions = {
  type: 'loop',
  pagination: false,
  perPage: 1,
  arrows: false,
  autoWidth: true,
  // focus: 'center',
  rewind: true,
  rewindByDrag: true,
  updateOnMove: true,
  gap: -160,
  focus: 'center',
  speed: 1200,
  flickPower: 1,
  dragMinThreshold: 1,

  breakpoints: {
    768: {
      gap: -80,
    },
    1439: {
      rewind: false,
      drag: true,
      perPage: 5,
      clones: 4,
    }
  },
};

const thumbnailsSliderOptions = {
  type: 'loop',
  focus: 'center',
  pagination: false,
  autoWidth: true,
  // perMove: 7,
  speed: 800,
  // perPage: 7,
  updateOnMove: true,
  dragMinThreshold: {
    mouse: 4,
    touch: 10,
  },
  breakpoints: {
    1439: {
      // perMove: undefined,
      speed: 1000,
      drag: true,
      clones: 4,
    }
  },
}

const initSlider = (mainClass, tabsClass) => {

  const main = new Splide(mainClass, mainSliderOptions);

  var thumbnails = new Splide(tabsClass, thumbnailsSliderOptions);

  main.sync(thumbnails);
  main.mount();
  thumbnails.mount();
  return [main, thumbnails]
}

const defaultOptions = {
  widthDefinitions: {
    mobile: {
      min: 0,
      max: 768,
      inclusion: "[]",
      onEnter: () => {
        const [main] = initSlider('.tv-hosts-tablet-and-mobile', '.heroes-tabs-tablet-and-mobile');
        const slidesList = main.Components.Slides;
        let slidesNeighbors = [slidesList.getAt(1).slide, slidesList.getAt(-1).slide];

        if (window.location.host.split('.')[0] === 'videos') {
          main.refresh();
        }

        let slidesNeighbors2 = [slidesList.getAt(2).slide, slidesList.getAt(-2).slide];
        slidesNeighbors2.forEach((itm, i) => {
          itm.style.opacity = 1;
          itm.classList.add('tv-hosts__slide_overlay');
        })

        slidesList.getAt(0).slide.querySelector('img').style.transform = 'translateX(30px)';
        slidesList.getAt(-1).slide.querySelector('img').style.transform = 'translateX(5px)';
        slidesList.getAt(1).slide.querySelector('img').style.transform = 'translateX(45px)';
        slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(-5px)';
        slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(27px)';

        main.on('active', (slide) => {
          if (slide.isClone) return;

          slidesNeighbors2.forEach((itm) => {
            itm.style.opacity = 0;
            const img = itm.children[0].children[0];
            img.style = '';
            itm.classList.remove('tv-hosts__slide_overlay');
          })
          // slidesNeighbors.forEach((itm) => {
          //   itm.querySelector('img').style.transform = '';
          // })
          slidesNeighbors2 = [slidesList.getAt(slide.index + 2).slide, slidesList.getAt(slide.index - 2).slide];
          slidesNeighbors = [slidesList.getAt(slide.index + 1).slide, slidesList.getAt(slide.index - 1).slide];
          slidesNeighbors2.forEach((itm) => {
            itm.style.opacity = 1;
            const img = itm.children[0].children[0];
            img.style = '';
            itm.classList.add('tv-hosts__slide_overlay');
          })

          switch (slide.index) {
            case 0:
              slidesList.getAt(0).slide.querySelector('img').style.transform = 'translateX(30px)';
              slidesList.getAt(-1).slide.querySelector('img').style.transform = 'translateX(5px)';
              slidesList.getAt(1).slide.querySelector('img').style.transform = 'translateX(45px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(-5px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(27px)';
              return;
            case 1:
              slidesList.getAt(1).slide.querySelector('img').style.transform = 'translateX(31px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(0px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(38px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(24px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(-12px)';
              return;
            case 2:
              slidesList.getAt(2).slide.querySelector('img').style.transform = 'translateX(22px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(-9px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(38px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(-20px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(45px)';
              return;
            case 3:
              slidesList.getAt(3).slide.querySelector('img').style.transform = 'translateX(14px) translateY(2px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(-4px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(42px) translateY(1px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(-12px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(36px)';
              return
            case 4:
              slidesList.getAt(4).slide.querySelector('img').style.transform = 'translateX(35px) translateY(5px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(-20px) translateY(2px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(46px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(-7px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(50px)';
              return
            case 5:
              slidesList.getAt(5).slide.querySelector('img').style.transform = 'translateX(24px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(-10px) translateY(5px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(54px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(-34px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(45px)';
              return
            case 6:
              slidesList.getAt(6).slide.querySelector('img').style.transform = 'translateX(31px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(-9px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(45px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(-3px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(44px)';
              return
            case 7:
              slidesList.getAt(7).slide.querySelector('img').style.transform = 'translateY(2px) translateX(30px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(-4px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(38px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(-21px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(39px)';
              return
            case 8:
              slidesList.getAt(8).slide.querySelector('img').style.transform = 'translateX(22px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(-5px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(42px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(-10px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(33px)';
              return
            case 9:
              slidesList.getAt(9).slide.querySelector('img').style.transform = 'translateX(28px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(-5px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(42px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(-10px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(41px)';
              return
          }
          // slide.update();
        })
      },
    },
    tablet: {
      min: 768,
      max: 1440,
      inclusion: "()",
      onEnter: () => {
        const [main] = initSlider('.tv-hosts-tablet-and-mobile', '.heroes-tabs-tablet-and-mobile');
        const slidesList = main.Components.Slides;
        let slidesNeighbors = [slidesList.getAt(1).slide, slidesList.getAt(-1).slide];

        if (window.location.host.split('.')[0] === 'videos') {
          main.refresh();
        }

        let slidesNeighbors2 = [slidesList.getAt(2).slide, slidesList.getAt(-2).slide];
        slidesNeighbors2.forEach((itm, i) => {
          itm.style.opacity = 1;
          itm.classList.add('tv-hosts__slide_overlay');
        })

        slidesList.getAt(0).slide.style.marginLeft = '-52px';
        slidesList.getAt(-1).slide.querySelector('img').style.transform = 'translateX(5px)';
        slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(76px)';
        slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-18px)';

        main.on('active', (slide) => {
          if (slide.isClone) return;

          slidesNeighbors2.forEach((itm) => {
            itm.style.opacity = 0;
            const img = itm.children[0].children[0];
            img.style = '';
            itm.classList.remove('tv-hosts__slide_overlay');
          })
          // slidesNeighbors.forEach((itm) => {
          //   itm.querySelector('img').style.transform = '';
          // })
          slidesNeighbors2 = [slidesList.getAt(slide.index + 2).slide, slidesList.getAt(slide.index - 2).slide];
          slidesNeighbors = [slidesList.getAt(slide.index + 1).slide, slidesList.getAt(slide.index - 1).slide];
          slidesNeighbors2.forEach((itm) => {
            itm.style.opacity = 1;
            const img = itm.children[0].children[0];
            img.style = '';
            itm.classList.add('tv-hosts__slide_overlay');
          })
          switch (slide.index) {
            case 0:
              slidesList.getAt(0).slide.style.marginLeft = '-35px';
              slidesList.getAt(-1).slide.querySelector('img').style.transform = 'translateX(5px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(76px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-18px)';
              return;
            case 1:
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(0px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(-10px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-56px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(21px)';
              return;
            case 2:
              slidesList.getAt(2).slide.querySelector('img').style.transform = 'translateX(35px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(40px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(-39px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(68px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-55px)';
              return;
            case 3:
              slidesList.getAt(3).slide.querySelector('img').style.transform = 'translateY(2px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(25px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(8px) translateY(5px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(55px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-36px)';
              return
            case 4:
              slidesList.getAt(4).slide.querySelector('img').style.transform = 'translateX(35px) translateY(5px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(5px) translateY(5px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(8px) translateY(5px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(81px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-36px)';
              return
            case 5:
              slidesList.getAt(5).slide.querySelector('img').style.transform = 'translateX(35px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(15px) translateY(5px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(0px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(81px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-36px)';

              return
            case 6:
              slidesList.getAt(6).slide.querySelector('img').style.transform = 'translateX(54px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(18px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(26px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(82px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-5px)';
              return
            case 7:
              slidesList.getAt(7).slide.querySelector('img').style.transform = 'translateY(2px) translateX(40px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(18px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(30px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(82px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(16px)';
              return
            case 8:
              slidesList.getAt(8).slide.querySelector('img').style.transform = 'translateY(2px) translateX(25px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(8px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(30px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(30px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(16px)';
              return
          }
          slide.update();
        })
      },
    },
    desktop: {
      min: 1440,
      max: 10000,
      inclusion: "[]",
      onEnter: () => {
        const [main] = initSlider('.tv-hosts-tablet-and-mobile', '.heroes-tabs-tablet-and-mobile');
        const slidesList = main.Components.Slides;
        let slidesNeighbors = [slidesList.getAt(1).slide, slidesList.getAt(-1).slide];

        if (window.location.host.split('.')[0] === 'videos') {
          main.refresh();
        }

        // TODO: Standartize this
        let slidesNeighbors3 = [slidesList.getAt(3).slide, slidesList.getAt(-3).slide];
        slidesNeighbors3.forEach((itm, i) => {
          itm.style.opacity = 1;
          itm.classList.add('tv-hosts__slide_overlay');
        })

        // neighobrs of slidesNeighbors2
        let slidesNeighbors2 = [slidesList.getAt(2).slide, slidesList.getAt(-2).slide];
        slidesNeighbors2.forEach((itm, i) => {
          itm.style.opacity = 1;
        })

        slidesList.getAt(0).slide.querySelector('img').style.transform = 'translateX(48px) translateY(2px)';
        slidesList.getAt(-1).slide.querySelector('img').style.transform = 'translateX(45px)';
        slidesList.getAt(1).slide.querySelector('img').style.transform = 'translateX(44px)';
        slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(31px)';
        slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(4px)';

        slidesNeighbors3[0].querySelector('img').style.transform = 'translateX(-40px)';
        slidesNeighbors3[1].querySelector('img').style.transform = 'translateX(70px)';

        main.on('active', (slide) => {
          if (slide.isClone) return;

          slidesNeighbors3.forEach((itm) => {
            itm.style.opacity = 0;
            const img = itm.children[0].children[0];
            img.style = '';
            itm.classList.remove('tv-hosts__slide_overlay');
          })

          slidesNeighbors2 = [slidesList.getAt(slide.index + 2).slide, slidesList.getAt(slide.index - 2).slide];
          slidesNeighbors3 = [slidesList.getAt(slide.index + 3).slide, slidesList.getAt(slide.index - 3).slide];
          slidesNeighbors = [slidesList.getAt(slide.index + 1).slide, slidesList.getAt(slide.index - 1).slide];

          slidesNeighbors2.forEach((itm) => {
            itm.style.opacity = 1;
          })

          slidesNeighbors3.forEach((itm, i) => {
            itm.style.opacity = 1;
            const img = itm.children[0].children[0];
            img.style = '';
            itm.classList.add('tv-hosts__slide_overlay');
          })

          switch (slide.index) {
            case 0:
              slidesList.getAt(0).slide.querySelector('img').style.transform = 'translateX(48px) translateY(2px)';
              slidesList.getAt(-1).slide.querySelector('img').style.transform = 'translateX(45px)';
              slidesList.getAt(1).slide.querySelector('img').style.transform = 'translateX(44px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(31px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(4px)';

              slidesNeighbors3[0].querySelector('img').style.transform = 'translateX(-40px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translateX(70px)';
              return;
            case 1:
              slidesList.getAt(1).slide.querySelector('img').style.transform = 'translateX(49px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(21px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(25px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-24px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(40px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translateX(0px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translateX(63px)';
              return;
            case 2:
              slidesList.getAt(2).slide.querySelector('img').style.transform = 'translateX(33px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(39px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(9px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(26px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(52px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translateX(-2px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translateX(75px)';
              return;
            case 3:
              slidesList.getAt(3).slide.querySelector('img').style.transform = 'translateX(15px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(38px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(38px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-2px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(59px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translateX(-10px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translateX(80px)';
              return
            case 4:
              slidesList.getAt(4).slide.querySelector('img').style.transform = 'translateX(54px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(6px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(19px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(25px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(42px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translateX(-2px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translateX(71px)';
              return
            case 5:
              slidesList.getAt(5).slide.querySelector('img').style.transform = 'translateX(29px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(55px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(32px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(20px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(31px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translateX(-29px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translateX(75px)';
              return
            case 6:
              slidesList.getAt(6).slide.querySelector('img').style.transform = 'translateX(54px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(19px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(40px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(2px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(73px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translateX(-26px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translateX(76px)';
              return
            case 7:
              slidesList.getAt(7).slide.querySelector('img').style.transform = 'translateX(52px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(40px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(13px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-8px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(35px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translateX(-36px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translateX(91px)';
              return
            case 8:
              slidesList.getAt(8).slide.querySelector('img').style.transform = 'translateX(32px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(47px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(21px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(-24px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(71px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translateX(-36px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translateX(63px)';
              return
            case 9:
              slidesList.getAt(9).slide.querySelector('img').style.transform = 'translateX(49px)';
              slidesNeighbors[1].querySelector('img').style.transform = 'translateX(23px)';
              slidesNeighbors[0].querySelector('img').style.transform = 'translateX(21px)';
              slidesNeighbors2[0].querySelector('img').style.transform = 'translateX(2px)';
              slidesNeighbors2[1].querySelector('img').style.transform = 'translateX(65px)';
              slidesNeighbors3[0].querySelector('img').style.transform = 'translateX(-36px)';
              slidesNeighbors3[1].querySelector('img').style.transform = 'translateX(72px)';
              return
          }
          // slide.update();
        })
      },
    },
  },
};

new ScreenSizeDetector(defaultOptions);